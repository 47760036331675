// src/components/SendForm.jsx

import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import synergyLogo from './images/synergy.png';
import zetaLogo from './images/zeta-logo.svg';
import ethLogo from './images/ethereum-logo.svg';
import bscLogo from './images/bsc-logo.svg';
import checkimg from './images/check.svg';
import metamaskLogo from './images/metamask.png';
import NetworkSelectFrom from './NetworkSelectFrom';
import NetworkSelectAssetEth from './NetworkSelectAssetEth';
import NetworkSelectAssetBsc from './NetworkSelectAssetBsc';

const ethers = require("ethers");
const { Option } = components;

const zetabscAddress = "0x0000028a2eb8346cd5c0267856ab7594b7a55308";
const sendzetabscAddress = "0x358E2cfC0E16444Ba7D3164Bbeeb6bEA7472c559";
const sendbnbbscAddress = "0x70e967acFcC17c3941E87562161406d41676FD83";
const bnbonZetaAddress = "0x48f80608b672dc30dc7e3dbbd0343c5f02c738eb";

const zetaethAddress = "0xf091867EC603A6628eD83D274E835539D82e9cc8";
const sendzetaethAddress = "0x910966E1C0Bc9FD74f499723c19Ff9799fE258a5";

const abi = [
  "function balanceOf(address) view returns (uint256)",
  "function approve(address spender, uint256 amount) returns (bool)",
  "function decimals() view returns (uint8)",
  "function symbol() view returns (string)",
  "function transfer(address to, uint amount) returns (bool)",
  "function transferFrom(address from, address to, uint amount) returns (bool)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "event Transfer(address indexed from, address indexed to, uint amount)",
  "function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)",
  "function totalSupply() external view returns (uint)",
  "function send(uint256 destinationChainId, bytes destinationAddress, uint256 zetaValueAndGas)"
];


const SendForm = () => {
  const [amount, setAmount] = useState('');
  const [fromNetwork, setFromNetwork] = useState(null);
  const [toNetwork, setToNetwork] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [toOptions, setToOptions] = useState([]);
  const [account, setAccount] = useState();
  const [ethBalace, setEthBalance] = useState(0);
  const [zetaBalance, setZetaBalance] = useState(0);
  const [zetaContractEth, setZetaContractEth] = useState('0xf091867EC603A6628eD83D274E835539D82e9cc8');
  const [EthToZetaAddress, setEthToZetaAddress] = useState('0x910966E1C0Bc9FD74f499723c19Ff9799fE258a5');

  const [chainId, setChainId] = useState(1);
  const [disable, setDisable] = useState(false);
  const [sendTx, setSendTx] = useState(true);

  const [AssetsBSC, setAssetsBSC] = useState(null);
  const [AssetsETH, setAssetsETH] = useState(null);
  const [assetError, setAssetError] = useState('');
  const [amountError, setAmountError] = useState('');

  const [approvedTx, setApproveTx] = useState('');
  const [evmTx, setEvmTx] = useState('');
  const [zetaTx, setZetaTx] = useState('');

  const [network, setNetwork] = useState('Ethereum');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectNetwork = (selectedNetwork) => {
    setNetwork(selectedNetwork);
    if(selectedNetwork === 'Ethereum')
    {
      setAsset('ZETA');
      switchNetwork(1);
    }
    else if(selectedNetwork === 'BSC')
    {
      setAsset('ZETA');
      switchNetwork(56);
    }
    setIsModalOpen(false);
  };


  const [asset, setAsset] = useState('ZETA');
  const [isModalOpenAssetEth, setIsModalOpenAssetEth] = useState(false); 
  const [isModalOpenAssetBsc, setIsModalOpenAssetBsc] = useState(false);    

  const handleOpenModalAsset = () => {
    if(network === 'Ethereum')
      setIsModalOpenAssetEth(true);
    else if(network === 'BSC')
      setIsModalOpenAssetBsc(true);
    setAmount('');
  };

  const handleOpenModalAssetEth = () => {
    setIsModalOpenAssetEth(true);
  };

  const handleCloseModalAssetEth = () => {
    setIsModalOpenAssetEth(false);
  };

  const handleSelectNetworkAssetEth = (selectedAsset) => {
    setAsset(selectedAsset);
    setIsModalOpenAssetEth(false);
  };  

  const handleOpenModalAssetBsc = () => {
    setIsModalOpenAssetBsc(true);
  };

  const handleCloseModalAssetBsc = () => {
    setIsModalOpenAssetBsc(false);
  };

  const handleSelectNetworkAssetBsc = (selectedAsset) => {
    setAsset(selectedAsset);
    setIsModalOpenAssetBsc(false);
  };    



  const connectWalletHandler = async () => {
    if (window.ethereum) {
      try {

        if(network === 'Ethereum')
          switchNetwork(1);
        else if(network === 'BSC')
          switchNetwork(56);

      } catch (error) {
        console.error(error);
      }
    } else {
      // If MetaMask is not installed, alert the user
      alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
    }
  };  



  const switchNetwork = async (targetChainId) => {
    try {
      if (!window.ethereum) throw new Error("MetaMask is not installed!");

      setChainId(targetChainId);
  
      // Convert targetChainId to hex value
      const hexChainId = ethers.toQuantity(targetChainId);
  
      // Request the current chain ID
      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
  
      if (currentChainId !== hexChainId) {
        try {
          // Attempt to switch to the target network
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: hexChainId }],
          });   

        } catch (switchError) {
          console.error('Switch network error:', switchError);
          // Handle other errors or provide guidance to the user
        }
      }
    } catch (error) {
      console.error('Failed to switch networks:', error);
      // Provide feedback to the user
    }


          // Request account access
          const newAccounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
          });          

        // Use ethers to get the provider and signer
        const provider = new ethers.BrowserProvider(window.ethereum);
        // It will prompt user for account connections if it isnt connected
        const signer = await provider.getSigner();
        console.log("Account:", await signer.getAddress());          

        // Fetch and display the balance
        const balance = await provider.getBalance(newAccounts[0]);
        const balanceInEth = ethers.formatEther(balance);
        console.log(`Balance of ${newAccounts[0]} is ${balanceInEth} ETH`);
        setAccount(newAccounts[0]);
        
        if(targetChainId === 1) {
          var tokenContract = new ethers.Contract(zetaethAddress, abi, provider);
          var zetaBalance = await tokenContract.balanceOf(newAccounts[0]);
          zetaBalance = ethers.formatEther(zetaBalance);
          console.log("zetBalanceEth = ",zetaBalance);
          setZetaBalance(parseFloat(zetaBalance));
          setEthBalance(parseFloat(balanceInEth));
        }  
        if(targetChainId === 56) {
          var tokenContract = new ethers.Contract(zetabscAddress, abi, provider);
          var zetaBalance = await tokenContract.balanceOf(newAccounts[0]);
          zetaBalance = ethers.formatEther(zetaBalance);
          console.log("zetBalanceEth = ",zetaBalance);
          setZetaBalance(parseFloat(zetaBalance));
          setEthBalance(parseFloat(balanceInEth));
        }  



  };
  
  const estimateGasCost = async (provider, transaction) => {
    const estimatedGas = await provider.estimateGas(transaction);
    const gasPrice = (await provider.getFeeData()).gasPrice;
    return (estimatedGas * gasPrice); // Total gas cost in wei
  };

    const handleFromNetworkChange = async (selectedOption) => {
      setFromNetwork(selectedOption);
      await switchNetwork(selectedOption.chainId);
    };
    

    const handleMax = async (e) => {
      e.preventDefault();

      if(!account) 
      { connectWalletHandler(); }
      else 
      {
          // Request account access
          const newAccounts = await window.ethereum.request({
            method: 'eth_requestAccounts',
          });          

        // Use ethers to get the provider and signer
        const provider = new ethers.BrowserProvider(window.ethereum);
        // It will prompt user for account connections if it isnt connected
        const signer = await provider.getSigner();
        console.log("Account:", await signer.getAddress());          

        // Fetch and display the balance
        const balance = await provider.getBalance(newAccounts[0]);
        const balanceInEth = ethers.formatEther(balance);
        console.log(`Balance of ${newAccounts[0]} is ${balanceInEth} ETH`);
        setAccount(newAccounts[0]);      

        if(asset === 'ZETA' && chainId === 1)
        {
          var tokenContract = new ethers.Contract(zetaethAddress, abi, provider);
          var zetaBalance = await tokenContract.balanceOf(newAccounts[0]);
          zetaBalance = ethers.formatEther(zetaBalance);
          console.log("zetBalanceEth = ",zetaBalance);
          setZetaBalance(parseFloat(zetaBalance));
          setAmount(zetaBalance);
          setEthBalance(parseFloat(balanceInEth));
        }
        else if(asset === 'ZETA' && chainId === 56)
        {
          var tokenContract = new ethers.Contract(zetabscAddress, abi, provider);
          var zetaBalance = await tokenContract.balanceOf(newAccounts[0]);
          zetaBalance = ethers.formatEther(zetaBalance);
          console.log("zetBalanceEth = ",zetaBalance);
          setZetaBalance(parseFloat(zetaBalance));
          setAmount(zetaBalance);
          setEthBalance(parseFloat(balanceInEth));
        }
        else if(asset === 'ETH' && chainId === 1)
        {
          const dummyTransaction = {
            to: sendbnbbscAddress, // or any valid address
            value: ethers.parseUnits("0", "ether"), // Use 0 value for estimation
          };
          const gasCostWei = await estimateGasCost(provider, dummyTransaction);
          const maxTransferableWei = (Number(balanceInEth) * 1000000000000000000) - Number(gasCostWei);
          const maxTransferableEth = ethers.formatEther(maxTransferableWei.toString());

          // Update the text box with the maximum transferable amount
          console.log(`Maximum transferable ETH: ${maxTransferableEth}`);
          setAmount(maxTransferableEth);
        }
        else if(asset === 'BNB' && chainId === 56)
        {
          const dummyTransaction = {
            to: sendbnbbscAddress, // or any valid address
            value: ethers.parseUnits("0", "ether"), // Use 0 value for estimation
          };
          const gasCostWei = await estimateGasCost(provider, dummyTransaction);
          const maxTransferableWei = (Number(balanceInEth) * 1000000000000000000) - Number(gasCostWei);
          const maxTransferableEth = ethers.formatEther(maxTransferableWei.toString());

          // Update the text box with the maximum transferable amount
          console.log(`Maximum transferable ETH: ${maxTransferableEth}`);
          setAmount(maxTransferableEth);
        }
      }
      
    } 
    


    const handleSubmit = async (e) => {
      e.preventDefault();

      setAmountError('');
      
      if(!amount)
      {
        setAmountError('Please Enter Amount.');
        setSendTx(false);
      }
      else
      {


         // Request account access
         const newAccounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });          

        // Use ethers to get the provider and signer
        const provider = new ethers.BrowserProvider(window.ethereum);
        // It will prompt user for account connections if it isnt connected
        const signer = await provider.getSigner();
        console.log("Account:", await signer.getAddress());  
      
        // Fetch and display the balance
        const balance = await provider.getBalance(newAccounts[0]);
        const balanceInEth = ethers.formatEther(balance);
        console.log(`Balance of ${newAccounts[0]} is ${balanceInEth} ETH`);
        setAccount(newAccounts[0]);       


        if(asset === 'ZETA' && chainId === 1)
        {
          var tokenContract = new ethers.Contract(zetaethAddress, abi, provider);
          var zetaBalance = await tokenContract.balanceOf(newAccounts[0]);
          zetaBalance = ethers.formatEther(zetaBalance);
          console.log("zetBalanceEth = ",zetaBalance);
          console.log("amount = ",amount);
          if(amount > zetaBalance)
          {
            setAmountError('Insufficient Balance.');
            setSendTx(false);
          }
          else {
            sendingtx();
          }
        }
        else if(asset === 'ZETA' && chainId === 56)
        {
          var tokenContract = new ethers.Contract(zetabscAddress, abi, provider);
          var zetaBalance = await tokenContract.balanceOf(newAccounts[0]);
          zetaBalance = ethers.formatEther(zetaBalance);
          console.log("zetBalanceEth = ",zetaBalance);
          if(amount > zetaBalance)
          {
            setAmountError('Insufficient Balance.');
            setSendTx(false);
          }
          else {
            sendingtx();
          }          
        }
        else if(asset === 'ETH' && chainId === 1)
        {
          const dummyTransaction = {
            to: sendbnbbscAddress, // or any valid address
            value: ethers.parseUnits("0", "ether"), // Use 0 value for estimation
          };
          const gasCostWei = await estimateGasCost(provider, dummyTransaction);
          const maxTransferableWei = (Number(balanceInEth) * 1000000000000000000) - Number(gasCostWei);
          const maxTransferableEth = ethers.formatEther(maxTransferableWei.toString());

          // Update the text box with the maximum transferable amount
          console.log(`Maximum transferable ETH: ${maxTransferableEth}`);

          if(amount > maxTransferableEth)
          {
            setAmountError('Insufficient Funds after Fees.');
            setSendTx(false);
          }
          else {
            sendingtx();
          }          
        }
        else if(asset === 'BNB' && chainId === 56)
        {
          const dummyTransaction = {
            to: sendbnbbscAddress, // or any valid address
            value: ethers.parseUnits("0", "ether"), // Use 0 value for estimation
          };
          const gasCostWei = await estimateGasCost(provider, dummyTransaction);
          const maxTransferableWei = (Number(balanceInEth) * 1000000000000000000) - Number(gasCostWei);
          const maxTransferableEth = ethers.formatEther(maxTransferableWei.toString());

          // Update the text box with the maximum transferable amount
          console.log(`Maximum transferable ETH: ${maxTransferableEth}`);

          if(amount > maxTransferableEth)
          {
            setAmountError('Insufficient Funds after Fees.');
            setSendTx(false);
          }
          else {
            sendingtx();
          }           
        }
      }
  
      // Implement the logic to handle form submission
    };
  

    async function sendingtx() {

        setDisable('disabled');
        console.log("sendTx = ", sendTx);
        console.log("amountError is empty");
        console.log("amountError = ", amountError);
        if(network === 'BSC' && asset === 'ZETA')
        {
        
          const contract = sendzetabscAddress;
          const tokenContract = zetabscAddress;
          const amountToApprove = amount * 1000000000000000000;
          const destinationChainId = 7000;
          const destinationAddress = account;
    
          console.log("Inside");
    
          const url = "https://zeta-api.synergynodes.com/zeta-chain/crosschain/convertGasToZeta?chainId=56&gasLimit=500000";
          console.log("url = ",url);
          const response = await fetch(url);
          const data = await response.json();
    
          console.log("url = ",url);
          console.log("data = ",data);
        
          const gasFee = Number(data.outboundGasInZeta);
          const protocolFee = Number(data.protocolFeeInZeta);
          // const zetaValueAndGas = gasFee.add(protocolFee) * 1000000000000000000;
          const zetaValueAndGas = gasFee + protocolFee;
    
          console.log("contract = ", contract);
          console.log("tokenContract = ", tokenContract);
          console.log("amountToApprove = ", amountToApprove);
          console.log("destinationChainId = ", destinationChainId);
          console.log("destinationAddress = ", destinationAddress);      
          console.log("zetaValueAndGas = ", gasFee);
    
    
          ensureAllowanceAndSend(contract, tokenContract, amountToApprove, destinationChainId, destinationAddress, amountToApprove)
          .then(() => console.log('Operation successful'))
          .catch((error) => console.error('Operation failed:', error));   
          
        }
        else if(network === 'Ethereum' && asset === 'ZETA')
        {
        
          const contract = sendzetaethAddress;
          const tokenContract = zetaethAddress;
          const amountToApprove = amount * 1000000000000000000;
          const destinationChainId = 7000;
          const destinationAddress = account;
    
          console.log("Inside");
    
          const url = "https://zeta-api.synergynodes.com/zeta-chain/crosschain/convertGasToZeta?chainId=56&gasLimit=500000";
          console.log("url = ",url);
          const response = await fetch(url);
          const data = await response.json();
    
          console.log("url = ",url);
          console.log("data = ",data);
        
          const gasFee = Number(data.outboundGasInZeta);
          const protocolFee = Number(data.protocolFeeInZeta);
          // const zetaValueAndGas = gasFee.add(protocolFee) * 1000000000000000000;
          const zetaValueAndGas = gasFee + protocolFee;
    
          console.log("contract = ", contract);
          console.log("tokenContract = ", tokenContract);
          console.log("amountToApprove = ", amountToApprove);
          console.log("destinationChainId = ", destinationChainId);
          console.log("destinationAddress = ", destinationAddress);      
          console.log("zetaValueAndGas = ", gasFee);
    
    
          ensureAllowanceAndSend(contract, tokenContract, amountToApprove, destinationChainId, destinationAddress, amountToApprove)
          .then(() => console.log('Operation successful'))
          .catch((error) => console.error('Operation failed:', error));   
          
        }
        else if(network === 'Ethereum' && asset === 'ETH')
        {
        
          const contract = sendbnbbscAddress;
          const tokenContract = sendbnbbscAddress;
          const amountToApprove = amount * 1000000000000000000;
          const destinationChainId = 7000;
          const destinationAddress = account;
    
          console.log("Inside");
    
          const url = "https://zeta-api.synergynodes.com/zeta-chain/crosschain/convertGasToZeta?chainId=56&gasLimit=500000";
          console.log("url = ",url);
          const response = await fetch(url);
          const data = await response.json();
    
          console.log("url = ",url);
          console.log("data = ",data);
        
          const gasFee = Number(data.outboundGasInZeta);
          const protocolFee = Number(data.protocolFeeInZeta);
          // const zetaValueAndGas = gasFee.add(protocolFee) * 1000000000000000000;
          const zetaValueAndGas = gasFee + protocolFee;
    
          console.log("contract = ", contract);
          console.log("tokenContract = ", tokenContract);
          console.log("amountToApprove = ", amountToApprove);
          console.log("destinationChainId = ", destinationChainId);
          console.log("destinationAddress = ", destinationAddress);      
          console.log("zetaValueAndGas = ", gasFee);
    
    
          ensureAllowanceAndSendEth(contract, tokenContract, amountToApprove, destinationChainId, destinationAddress, amountToApprove)
          .then(() => console.log('Operation successful'))
          .catch((error) => console.error('Operation failed:', error));   
          
        }
        else if(network === 'BSC' && asset === 'BNB')
        {
        
          const contract = sendbnbbscAddress;
          const tokenContract = sendbnbbscAddress;
          const amountToApprove = amount * 1000000000000000000;
          const destinationChainId = 7000;
          const destinationAddress = account;
    
          console.log("Inside");
    
          const url = "https://zeta-api.synergynodes.com/zeta-chain/crosschain/convertGasToZeta?chainId=56&gasLimit=500000";
          console.log("url = ",url);
          const response = await fetch(url);
          const data = await response.json();
    
          console.log("url = ",url);
          console.log("data = ",data);
        
          const gasFee = Number(data.outboundGasInZeta);
          const protocolFee = Number(data.protocolFeeInZeta);
          // const zetaValueAndGas = gasFee.add(protocolFee) * 1000000000000000000;
          const zetaValueAndGas = gasFee + protocolFee;
    
          console.log("contract = ", contract);
          console.log("tokenContract = ", tokenContract);
          console.log("amountToApprove = ", amountToApprove);
          console.log("destinationChainId = ", destinationChainId);
          console.log("destinationAddress = ", destinationAddress);      
          console.log("zetaValueAndGas = ", gasFee);
    
    
          ensureAllowanceAndSendEth(contract, tokenContract, amountToApprove, destinationChainId, destinationAddress, amountToApprove)
          .then(() => console.log('Operation successful'))
          .catch((error) => console.error('Operation failed:', error));   
          
        }        
    }


    async function ensureAllowanceAndSend(contractAddress, tokenContractAddress, amountToApprove, destinationChainId, destinationAddress, zetaValueAndGas) {
      
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      var tokenContract = new ethers.Contract(tokenContractAddress, abi, provider);
      const currentAllowance = await tokenContract.allowance(signerAddress, contractAddress);
    
      const currentAllowanceBN = Number(currentAllowance);

      if (Number(currentAllowance) < amountToApprove) {
        tokenContract = new ethers.Contract(tokenContractAddress, abi, signer);
        const approveTx = await tokenContract.approve(contractAddress, ethers.parseEther((amountToApprove / 1000000000000000000).toString()));
        await approveTx.wait(); // Wait for the approval transaction to be mined
        console.log('Tokens approved successfully');
        setApproveTx('done');
      } else {
        console.log('Sufficient allowance already set.');
        setApproveTx('done');
      }

      var contract = new ethers.Contract(contractAddress, abi, signer);
      // Now that allowance is ensured, call the send function
      const sendTxResponse = await contract.send(destinationChainId, destinationAddress, zetaValueAndGas.toString());
      console.log('Send transaction submitted:', sendTxResponse);
      await sendTxResponse.wait();
      console.log("BSC Tx = ", sendTxResponse.hash);
      // setEvmTx(sendTxResponse.hash);
      if(chainId === 1)
        setEvmTx('https://etherscan.io/tx/' + sendTxResponse.hash);
      else if(chainId === 56)
        setEvmTx('https://bscscan.com/tx/' + sendTxResponse.hash);
      console.log('Send transaction completed.');
      const apiUrl = 'https://zeta-api.synergynodes.com/zeta-chain/crosschain/inTxHashToCctx/' + sendTxResponse.hash;
      queryTx(apiUrl);
    }


    async function ensureAllowanceAndSendEth(contractAddress, tokenContractAddress, amountToApprove, destinationChainId, destinationAddress, zetaValueAndGas) {
      
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      

      let dummyTransaction = {
        to: sendbnbbscAddress,
        value: amountToApprove.toString(),
      };

      const gasCostWei = await estimateGasCost(provider, dummyTransaction);
      dummyTransaction = {
        to: sendbnbbscAddress,
        value: amountToApprove.toString(),
        gasLimit: 21000,
      };


      const sendTxResponse = await signer.sendTransaction(dummyTransaction);
      console.log('Send transaction submitted:', sendTxResponse);
      await sendTxResponse.wait();
      console.log("BSC Tx = ", sendTxResponse.hash);
      if(chainId === 1)
        setEvmTx('https://etherscan.io/tx/' + sendTxResponse.hash);
      else if(chainId === 56)
        setEvmTx('https://bscscan.com/tx/' + sendTxResponse.hash);
      console.log('Send transaction completed.');
      const apiUrl = 'https://zeta-api.synergynodes.com/zeta-chain/crosschain/inTxHashToCctx/' + sendTxResponse.hash;
      queryTx(apiUrl);
    }    


    async function queryTx(apiUrl) {
        try {
            const response = await fetch(apiUrl);
            const data = await response.json();
    
            // Check if the result is "not found"
            if (data.message === "not found") { // Adjust this condition based on the actual API response for "not found"
                console.log('Result not found, retrying in 5 seconds...');
                setTimeout(() => queryTx(apiUrl), 5000);
            } else {
                console.log('Result found:', data);
                setZetaTx('https://explorer.zetachain.com/cc/tx/' + data.inTxHashToCctx.cctx_index[0]);
            }
        } catch (error) {
            console.error('Error querying the API:', error);
            setTimeout(() => queryTx(apiUrl), 5000); // Retry on error
        }
    }



  return (
    <div>

    <NetworkSelectFrom
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onSelectNetwork={handleSelectNetwork}
    /> 

    <NetworkSelectAssetEth
      isOpen={isModalOpenAssetEth}
      onClose={handleCloseModalAssetEth}
      onSelectNetwork={handleSelectNetworkAssetEth}
    />   
    
    <NetworkSelectAssetBsc
      isOpen={isModalOpenAssetBsc}
      onClose={handleCloseModalAssetBsc}
      onSelectNetwork={handleSelectNetworkAssetBsc}
    />     

    <div className="mainTop">
      <img src={zetaLogo} /> Zetachain Bridge from <img src={synergyLogo} /> <a href="https://www.synergynodes.com" target="_blank">Synergy Nodes</a>
      <p>This is a one way Bridge between EVM chains to Zetachain. <br /> We will continue to build and add two way Bridge.</p>
      <p><b>Supported Wallets:</b> <img src={metamaskLogo} /> Metamask</p>
    </div>

    <div className='mainBox'>
    
    <form onSubmit={handleSubmit}>
        <div className='oneBox'>
          <div className='oneLeft'>
            From <br />
            <div className='divFrom' onClick={handleOpenModal} > 

            { network === 'Ethereum' && (asset === 'ETH' || asset === 'ZETA') ?
            <div>
              <img src={ethLogo} />
              <p className="top">Select Network</p>
              <p className="bottom">Ethereum</p>
            </div>
            : "" }

            { network === 'BSC' && (asset === 'BNB' || asset === 'ZETA') ?
            <div>
              <img src={bscLogo} />
              <p className="top">Select Network</p>
              <p className="bottom">BSC</p>
            </div>
            : "" }   

            </div>
          </div>
          <div className='oneRight'>
            To <br />
            <div className='divTo'>
              <img src={zetaLogo} />
              <p className="top">Network</p>
              <p className="bottom">Zetachain</p>
            </div>
          </div>          
        </div>
        <hr />
        <div className='oneBox'>
          <div className='oneLeftBottom'>
            Asset <br />
            <div className='divFrom' onClick={handleOpenModalAsset}>

            { asset === 'ZETA' ?
              <div>
                <img src={zetaLogo} />
                <p className="top">Select Asset</p>
                <p className="bottom">ZETA</p>
              </div>
            : "" }
            { asset === 'ETH' ?
              <div>
                <img src={ethLogo} />
                <p className="top">Select Asset</p>
                <p className="bottom">ETH</p>
              </div>
            : "" }
            { asset === 'BNB' ?
              <div>
                <img src={bscLogo} />
                <p className="top">Select Asset</p>
                <p className="bottom">BNB</p>
              </div>
            : "" }            

            </div>
          </div>
          <div className='oneRightBottom'>
            Amount {account ? <p onClick={handleMax} style={{display: 'inline-block', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer', color: '#fff', padding: '0px', margin: '0px', fontSize: '13px', color: 'deepskyblue'}}>(Max)</p> : "" } <br />
            <div className='divTo'>
              <input
                className='inputClass'
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder='Enter Amount'
                disabled={!account ? 'disabled' : ''}
              /> <br />
              { amountError ? <span className='error'>{amountError}</span> : "" }
            </div>
          </div>  
        </div>

        { account ?
        <div className="accDetails">
          <span>Wallet:</span>
          { account ? account : '' }
          <div className="balances">
            <p><span>{network === 'Ethereum' ? 'ETH' : 'BNB'} Balance:</span> { ethBalace ? ethBalace.toFixed(4) : '' }</p>
            <p><span>ZETA Balance:</span> { zetaBalance ? zetaBalance.toFixed(4) : '' }</p>
          </div>
        </div>
        : "" }

        { amount ?
        <div className="feeSection">
          <div className="miniBox">
            <p className='left'>Send Amount</p>
            <p className='right'>{Number(amount).toFixed(4)} {asset}</p>
          </div>
          <div className="miniBox">
            <p className='left'>Zeta Network Fees</p>
            <p className='right'>0 ZETA</p>
          </div>  
          <div className="miniBox">
            <p className='left'>Destination Fees</p>
            <p className='right'>0 ZETA</p>
          </div>                   
        </div>
        : "" }

        { !account ?
          <button type="button" onClick={connectWalletHandler}>Connect Wallet</button>
          : 
          <button type="submit" disabled={disable}>{ disable && !zetaTx ? <span className="loader2" /> : "" } { !zetaTx ? 'Submit' : 'Done!' }</button>
        }  
        </form>

        { asset === 'ZETA' ?

            <div className="progress">

            { chainId === 1 && !zetaTx ? <p>NOTE: Transfers from Ethereum Network takes time.</p> : ""}            

            {disable ? <p>1. Allowance Approval - {!approvedTx ? <span className="loader2" /> : <span className="tick-mark"><img src={checkimg} /></span> } </p>
            : "" }
          
            { approvedTx ? 
              <p>2. {network} Network Tx - { !evmTx ? <span className="loader2" /> : 
              <div className="myLink">
                <span className="tick-mark"><img src={checkimg} /></span> - <a href={evmTx} target='_blank'>Link</a> 
              </div>
              }  </p> 
            : "" }
          
            { evmTx ? 
              <p>3. Zetachain Network Tx - { !zetaTx ? <span className="loader2" /> : 
              <div className="myLink">
                <span className="tick-mark"><img src={checkimg} /></span> - <a href={zetaTx} target='_blank'>Link</a> 
              </div>  
              } </p>
            : "" }

            { zetaTx ? 
              <div className='txComplete'>Transfer is Complete.</div>
            : "" }

          </div>

        :

          <div className="progress">

          { chainId === 1 && !zetaTx ? <p>NOTE: Transfers from Ethereum Network takes time.</p> : ""}          
          
          { disable ? 
            <p>1. {network} Network Tx - { !evmTx ? <span className="loader2" /> : 
            <div className="myLink">
              <span className="tick-mark"><img src={checkimg} /></span> - <a href={evmTx} target='_blank'>Link</a> 
            </div>
            }  </p> 
          : "" }
        
          { evmTx ? 
            <p>2. Zetachain Network Tx - { !zetaTx ? <span className="loader2" /> : 
            <div className="myLink">
              <span className="tick-mark"><img src={checkimg} /></span> - <a href={zetaTx} target='_blank'>Link</a> 
            </div>  
            } </p>
          : "" } 

          { zetaTx ? 
            <div className='txComplete'>Transfer is Complete.</div>
          : "" }

          </div>        

        }        

    </div>

    <p className='powered'>Send is powered and secured by ZetaChain</p>



    </div>


  );
};

export default SendForm;
