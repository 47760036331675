import React, { useState } from 'react';
import './Modal.css'; // Create a Modal.css file for styling
import zetaLogo from './images/zeta-logo.svg';
import ethLogo from './images/ethereum-logo.svg';
import bscLogo from './images/bsc-logo.svg';

const NetworkSelectAssetEth = ({ isOpen, onClose, onSelectNetwork }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-box">
        <div className="modal-header">
          <h2>Select Asset</h2>
          <button onClick={onClose} className="close-button">&times;</button>
        </div>
        <div className="container">
            <div onClick={() => onSelectNetwork('ZETA')} className="box">
            <img src={zetaLogo} alt="Zeta Logo" /> <br />
                Zeta
            </div>        
            <div onClick={() => onSelectNetwork('ETH')} className="box">
            <img src={ethLogo} alt="Ethereum Logo" /> <br />
                ETH
            </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkSelectAssetEth;
